enum Constants {
  UPDATE_EDITOR_STATE = 'UPDATE_EDITOR_STATE',
  UPDATE_NODE_EDITOR_SETTINGS = 'UPDATE_NODE_EDITOR_SETTINGS',
  UPDATE_TRANSLATING = 'UPDATE_TRANSLATING',
  UPDATE_BASE_LANGUAGE = 'UPDATE_BASE_LANGUAGE',
  UPDATE_LANGUAGES = 'UPDATE_LANGUAGES',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
  UPDATE_FETCHING_FLOW = 'FETCHING_FLOW',
  UPDATE_DEFINITION = 'UPDATE_DEFINITION',
  UPDATE_NODE_DRAGGING = 'UPDATE_NODE_DRAGGING',
  UPDATE_FLOWS = 'UPDATE_FLOWS',
  UPDATE_PENDING_CONNECTIONS = 'UPDATE_PENDING_CONNECTIONS',
  REMOVE_PENDING_CONNECTION = 'REMOVE_PENDING_CONNECTION',
  UPDATE_COMPONENTS = 'UPDATE_COMPONENTS',
  UPDATE_CONTACT_FIELDS = 'UPDATE_CONTACT_FIELDS',
  UPDATE_GROUPS = 'UPDATE_GROUPS',
  UPDATE_RESULT_MAP = 'UPDATE_RESULT_MAP',
  UPDATE_ASSET_MAP = 'UPDATE_ASSET_MAP',
  UPDATE_NODES = 'UPDATE_NODES',
  UPDATE_ISSUES = 'UPDATE_ISSUES',
  UPDATE_METADATA = 'UPDATE_METADATA',
  UPDATE_EXITS = 'UPDATE_EXITS',
  UPDATE_NODE_EDITOR_OPEN = 'UPDATE_NODE_EDITOR_OPEN',
  UPDATE_CREATE_NODE_POSITION = 'UPDATE_CREATE_NODE_POSITION',
  UPDATE_DRAG_GROUP = 'UPDATE_DRAG_GROUP',
  UPDATE_TYPE_CONFIG = 'UPDATE_TYPE_CONFIG',
  UPDATE_USER_ADDING_ACTION = 'UPDATE_USER_ADDING_ACTION',
  INCREMENT_SUGGESTED_RESULT_NAME_COUNT = 'INCREMENT_SUGGESTED_RESULT_NAME_COUNT'
}

export default Constants;
